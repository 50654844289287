.no-match-wrapper {
  display: grid;
  grid-template-columns: 50px auto 50px;

  .header {
    margin: 0;
  }

  .img-wrapper {
    grid-column: 1 / 4;

    img {
      width: 100%;
      display: block;
    }
  }

  .copy {
    grid-column: 2 / 3;
    text-align: center;
    margin-top: 45px;
    margin-bottom: 150px;

    p {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    a {
      font-family: 'Chivo', sans-serif;
      font-weight: 300;
      font-style: italic;
      text-decoration: none;
      color: #ff0097;
      font-size: 26px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .no-match-wrapper {
    grid-template-columns: 50px 1fr 45px 1fr 50px;

    .header {
      font-size: 40px;
    }

    .img-wrapper {
      grid-column: 2 / 3;
    }

    .copy {
      grid-column: 4 / 5;
      margin-top: 0;
      margin-bottom: 0;
      align-self: center;
    }
  }
}
@media screen and (min-width: 1300px) {
  .no-match-wrapper {
    grid-template-columns: 12.5% 1fr 45px 1fr 12.5%;
  }
}

@media screen and (min-width: 1300px) {
  .no-match-wrapper {
    .header { font-size: 50px; }
  }
}