$magenta: #ff0097;

.rebrand-page-content {
  display: grid;
  grid-template-columns: 25px auto 25px;
  grid-template-rows: auto;
  grid-template-areas:
    ". copy ."
    "videos videos videos"
    ". copy2 ."
    "section1 section1 section1"
    ". section2 ."
    ". section3 ."
    ". copy3 ."
    "video2 video2 video2"
}

.rebrand-page-content {
  .copy { grid-area: copy; }

  .videos-wrapper {
    grid-area: videos;
    margin-top: 65px;
    margin-bottom: 40px;

    .video-wrapper {
      margin-top: 0;
    }
  }

  .videos-wrapper-2 {
    grid-area: video2;
  }

  .copy2 {
    grid-area: copy2;
    margin-bottom: 30px;
  }

  .copy3 {
    grid-area: copy3;
    margin-top: 30px;
  }

  .section1 {
    grid-area: section1;
    text-align: center;

    p {
      margin-bottom: 40px;
    }

    img {
      width: 100%;
      display: block;
      margin-bottom: 40px;

      &:first-of-type {
        margin-bottom: 20px;
      }

      &:nth-of-type(2) {
        display: none;
      }
    }
  }

  .section2 {
    margin-bottom: 40px;
    grid-area: section2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 64px;
    grid-template-areas:
      "image1 image2";

    img {
      width: 100%;
      display: block;
      max-width: 300px;
      place-self: center;

      &:first-of-type {
        grid-area: image1;
      }

      &:last-of-type {
        grid-area: image2;
      }

      &:nth-of-type(2) {
        display: none;
      }
    }
  }

  .section3 {
    grid-area: section3;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

    img {
      width: 100%;

      &:nth-of-type(2) {
        display: none;
      }

      &:last-of-type {
        display: none;
      }
    }
  }

  .video-wrapper {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }

  .video {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}

.rebrand-page-content {
  grid-template-columns: 50px auto 50px;
}

@media screen and (min-width: 768px) {
  .rebrand-page-content {
    .section1 {
      img {
        margin-bottom: 100px;

        &:first-of-type {
          margin-bottom: 70px;
        }

        &:nth-of-type(2) {
          display: block;
        }

        &:last-of-type {
          display: none;
        }
      }
    }

    .section2 {
      margin-bottom: 100px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .rebrand-page-content {
    grid-template-columns: 12.5%  75% 12.5%;
    grid-template-areas:
    ". copy ."
    ". videos ."
    ". copy2 ."
    ". section1 ."
    ". section2 ."
    ". section3 ."
    ". copy3 ."
    ". video2 .";

    .videos-wrapper {
      margin-bottom: 70px;
    }

    .copy3 {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .videos-wrapper-2 {
      margin-top: 30px;

      .video-wrapper {
        margin-top: 0;
      }
    }

    .copy2 {
      margin-bottom: 55px;
    }

    .section1 {
      img {
        margin-bottom: 100px;

        &:first-of-type {
          margin-bottom: 70px;
        }
      }
    }

    .section2 {
      margin-bottom: 100px;
      grid-template-columns: 9fr 2fr 9fr 2fr 9fr;
      grid-column-gap: 0;
      grid-template-areas:
        "image1 . image3 . image2";

      img {
        &:nth-of-type(1) {
          place-self: start;
        }

        &:nth-of-type(2) {
          display: block;
          grid-area: image3;
        }

        &:nth-of-type(3) {
          place-self: end;
        }
      }
    }

    .section3 {
      display: grid;
      grid-template-columns: 58% 9.5% 32.5%;
      grid-template-rows: 1fr 0px 1fr;
      justify-content: initial;
      align-items: initial;

      img {
        &:first-of-type {
          max-width: 854px;
          grid-column: 1 / span 1;
          grid-row: 1 / span 3;
        }

        &:nth-of-type(2) {
          align-self: start;
          grid-column: 3 / span 3;
          grid-row: 1 / span 1;
        }

        &:last-of-type {
          align-self: end;
          grid-column: 3 / span 3;
          grid-row: 3 / span 3;
        }

        &:not(:first-of-type) {
          display: block;
          max-width: 506px;
        }
      }
    }
  }
}