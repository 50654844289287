$magenta: #ff0097;

.about-wrapper {
  display: grid;
  grid-template-columns: 50px auto 50px;
  grid-template-rows: auto;
  margin-bottom: 80px;

  .intro {
    text-align: center;
    grid-column: 1 / 4;

    h1 {
      margin: 28px 0;
    }
  }

  .text {
    margin: 0 50px;
  }

  .section1 img,
  .section2 img {
    width: 100%;
    display: block;
  }

  .section1 {
    grid-column: 1 / 4;

    img {
      margin-top: 85px;
      margin-bottom: 35px;
    }

    .text {
      text-align: left;
    }
  }

  .section2 {
    grid-column: 1 / 4;

    img {
      margin-top: 85px;
      margin-bottom: 35px;
    }

    .text {
      a {
        font-style: italic;
        text-decoration: none;
        color: $magenta;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .paws {
    width: 64px;
  }
}

@media screen and (min-width: 768px) {
  .about-wrapper {
    display: grid;
    justify-items: center;
    grid-template-columns: 12.5% 75% 12.5%;
    grid-template-rows: auto 75px auto 100px auto 200px;
    margin-bottom: 0;

    .intro {
      grid-column: 2 / 2;
      grid-row: 1 / 1;
    }

    .section1 img,
    .section2 img {
      width: 100%;
      max-width: 600px;
      margin: 0;
    }

    .section1 {
      grid-column: 2 / 3;
      grid-row: 3 / 4;
      display: grid;
      grid-template-columns: 1fr 45px 1fr;
      grid-template-rows: auto;
    }

    .section1 img {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    };

    .text {
      margin: 0;
      font-size: 22px;
      max-width: 600px;
      align-self: center;
    }

    .section1 .text {
      grid-column: 3 / 4;
    };

    .section2 {
      grid-column: 2 / 3;
      grid-row: 5 / 6;
      display: grid;
      grid-template-columns: 1fr 45px 1fr;
      grid-template-rows: auto;
    }

    .section2 img {
      grid-column: 3 / 4;
      grid-row: 1 / 2;
      place-self: center;
    };

    .section2 .text {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    };
  }
}

@media screen and (min-width: 1024px) {
  .about-wrapper {
    grid-template-rows: auto 75px auto 100px auto 200px;

    .text {
      font-size: 26px;
    }
  }
}