$magenta: #ff0097;

#root {
  position: relative;
}

#loading-wrapper {
  background-color: #fff4e4;
  width: 100%;
  height: 100%;
  position: absolute;
  padding-top: 12px;
  z-index: 10;
  transition: opacity 1s ease-in-out;
  opacity: 1;
  text-align: center;
  top: 0;
}

.name {
  font-family: 'Shrikhand', cursive;
  color: $magenta;
  font-size: 91px;
  color: #ff0097;
  margin-left: 48px;
  text-align: left;
  margin-bottom: 10px;
  line-height: 1.1em;
}

.animated-words {
  span {
    position: absolute;
    opacity: 0;
    left: 48px;
    font-family: 'Chivo', sans-serif;
    font-weight: 400;
    font-style: italic;
    font-size: 60px;
  }

  span:nth-of-type(1) {
    animation:
      1s ease 0s normal forwards 1 fadein,
      1s ease 1s normal forwards 1 fadeout;
  }

  span:nth-of-type(2) {
    animation:
      1s ease 2s normal forwards 1 fadein,
      1s ease 3s normal forwards 1 fadeout;
  }

  span:nth-of-type(3) {
    animation:
      1s ease 4s normal forwards 1 fadein,
      1s ease 5s normal forwards 1 fadeout;
  }

  span:nth-of-type(4) {
    animation:
      1s ease 6s normal forwards 1 fadein,
      1s ease 7s normal forwards 1 fadeout;
  }
}

.letters {
  animation: 1s ease 7s normal forwards 1 fadeout;
}

@keyframes fadein{
  0% { opacity: 0; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeout{
  0% { opacity: 1; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

@media screen and (min-width: 1024px) {
  #loading-wrapper {
    padding-top: 12px;

    .name {
      margin-left: 78px;
    }
  }

  .animated-words {
    span {
      left: 80px;
    }
  }
}