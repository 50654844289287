$magenta: #ff0097;

.grid-page-content {
  display: grid;
  grid-template-columns: 50px auto 50px;
  grid-template-rows: auto;
  grid-template-areas:
    ". copy ."
    "header-image header-image header-image"
    "image-grid image-grid image-grid";

  .copy {
    grid-area: copy;
  }

  .text {
    a {
      color: $magenta;
      text-decoration: none;
      font-style: italic;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .header-image-wrapper {
    grid-area: header-image;

    margin-top: 50px;
    margin-bottom: 50px;

    img {
      width: 100%;
      display: block;
      max-width: 1280px;
    }
  }

  .image-grid {
    grid-area: image-grid;

    img {
      width: 100%;
      margin-bottom: 50px;
      display: block;

      &:nth-of-type(5) {
        display: none;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &.cat-grid {
      img {
        &:nth-of-type(5) {
          display: block;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .grid-page-content {
    .image-grid {
      display: grid;
      grid-template-columns: 0px 1fr  1fr 0px;
      grid-template-rows: auto auto auto auto auto auto;
      grid-column-gap: 30px;
      grid-row-gap: 30px;

      img {
        margin-bottom: 0;

        &:nth-of-type(1) {
          grid-column: 2 / 3;
          grid-row: 1 / 2;
        }

        &:nth-of-type(2) {
          grid-column: 3 / 4;
          grid-row: 1 / 2;
        }

        &:nth-of-type(3) {
          grid-column: 2 / 3;
          grid-row: 2 / 3;
        }

        &:nth-of-type(4) {
          grid-column: 3 / 4;
          grid-row: 2 / 3;
        }

        &:nth-of-type(5) {
          grid-column: 2 / 3;
          grid-row: 3 / 5;
          display: block;
          place-self: center;
        }

        &:nth-of-type(6) {
          display: none;
        }

        &:nth-of-type(7) {
          grid-column: 3 / 4;
          grid-row: 3 / 4;
        }

        &:nth-of-type(8) {
          grid-column: 3 / 4;
          grid-row: 4/ 5;
        }

        &:nth-of-type(9) {
          grid-column: 2 / 3;
          grid-row: 5 / 6;
        }

        &:nth-of-type(10) {
          grid-column: 3 / 4;
          grid-row: 5 / 6;
        }

        &:nth-of-type(11) {
          grid-column: 2 / 3;
          grid-row: 6 / 7;
        }

        &:nth-of-type(12) {
          grid-column: 3 / 4;
          grid-row: 6 / 7
        }
      }
    }

    .image-grid.cat-grid {
      grid-template-rows: auto auto auto;

      img {
        &:nth-of-type(1) {
          grid-column: 2 / 3;
          grid-row: 1 / 2;
        }

        &:nth-of-type(2) {
          grid-column: 3 / 4;
          grid-row: 1 / 2;
        }

        &:nth-of-type(3) {
          grid-column: 2 / 3;
          grid-row: 2 / 3;
        }

        &:nth-of-type(4) {
          grid-column: 3 / 4;
          grid-row: 2 / 3;
        }

        &:nth-of-type(5) {
          grid-column: 2 / 3;
          grid-row: 3 / 4;
        }

        &:nth-of-type(6) {
          grid-column: 3 / 4;
          grid-row: 3 / 4;
          display: block;
        }
      }
    }
  }
}


@media screen and (min-width: 1024px) {
  .grid-page-content {
    grid-template-columns: 12.5%  75% 12.5%;
    grid-template-areas:
    ". copy ."
    ". header-image ."
    ". image-grid .";

    .title {
      margin-top: 20px;
      font-size: 60px;
    }

    .role {
      font-size: 25px;
      margin-bottom: 15px;
    }

    .description {
      max-width: 700px;
      margin: 0 auto;
      font-size: 23px;
      line-height: 1.4em;
    }

    .header-image-wrapper {
      margin-top: 65px;
      margin-bottom: 50px;
    }

    .image-grid {
      display: grid;
      grid-template-columns: 1fr  1fr 1fr;
      grid-template-rows: auto auto auto auto;
      grid-column-gap: 30px;
      grid-row-gap: 30px;

      @media screen and (min-width: 1300px) {
        grid-column-gap: 50px;
        grid-row-gap: 50px;
      }

      img {
        margin-bottom: 0;

        &:nth-of-type(1) {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
        }

        &:nth-of-type(2) {
          grid-column: 2 / 3;
          grid-row: 1 / 2;
        }

        &:nth-of-type(3) {
          grid-column: 3 / 4;
          grid-row: 1 / 2
        }

        &:nth-of-type(4) {
          grid-column: 1 / 2;
          grid-row: 2 / 3;
        }

        &:nth-of-type(5) {
          grid-column: 2 / 3;
          grid-row: 2 / 4;
          place-self: center;
          display: block;
        }

        &:nth-of-type(6) {
          display: none;
        }

        &:nth-of-type(7) {
          grid-column: 3 / 4;
          grid-row: 2 / 3;
        }

        &:nth-of-type(8) {
          grid-column: 1 / 2;
          grid-row: 3 / 4;
        }

        &:nth-of-type(9) {
          grid-column: 3 / 4;
          grid-row: 3 / 4;
        }

        &:nth-of-type(10) {
          grid-column: 1 / 2;
          grid-row: 4 / 5;
        }

        &:nth-of-type(11) {
          grid-column: 2 / 3;
          grid-row: 4 / 5;
        }

        &:nth-of-type(12) {
          grid-column: 3 / 4;
          grid-row: 4 / 5;
        }
      }
    }

    .image-grid.cat-grid {
      grid-template-rows: auto auto;

      img {
        &:nth-of-type(1) {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
        }

        &:nth-of-type(2) {
          grid-column: 2 / 3;
          grid-row: 1 / 2;
        }

        &:nth-of-type(3) {
          grid-column: 3 / 4;
          grid-row: 1 / 2;
        }

        &:nth-of-type(4) {
          grid-column: 1 / 2;
          grid-row: 2 / 3;
        }

        &:nth-of-type(5) {
          grid-column: 2 / 3;
          grid-row: 2 / 3;
        }

        &:nth-of-type(6) {
          grid-column: 3 / 4;
          grid-row: 2 / 3;
          display: block;
        }
      }
    }
  }
}