.project-footer {
  margin-right: 25px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.project-footer a {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  text-decoration: none;
  color: inherit;

  .eyes {
    align-self: center;
  }

  span {
    align-self: center;
    font-family: 'Chivo', sans-serif;
    font-weight: 300;
    font-style: italic;
    padding: 0 7px;
  }

  .arrow {
    align-self: center;
    padding-top: 15px;
  }
}

@media screen and (min-width: 640px) {
  .project-footer {
    margin-right: 50px;
  }
}

@media screen and (min-width: 1024px) {
  .project-footer {
    margin-right: 80px;
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .project-footer a {
    font-size: 25px;

    span {
      padding: 0 10px;
    }
  }
}