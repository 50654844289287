body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrapper {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-template-areas: "content";
  z-index: 1; }

.mobile-content {
  grid-area: content;
  grid-row: 1/1;
  width: 100%; }
  .mobile-content .project {
    position: relative;
    cursor: pointer;
    width: 100%;
    min-height: 175px; }
    .mobile-content .project img {
      display: block;
      width: 100%; }
    .mobile-content .project h2 {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      font-family: 'Shrikhand', cursive;
      font-size: 50px;
      color: #ff0097;
      text-align: center; }

.desktop-content {
  display: none; }

@media screen and (min-width: 900px) {
  .mobile-content {
    display: none; }
  .desktop-content {
    overflow: hidden;
    display: grid;
    grid-template-columns: 50px auto auto 50px;
    grid-template-rows: auto;
    grid-template-areas: ". list gif .";
    margin-top: 50px; }
    .desktop-content .list {
      min-width: 360px;
      grid-area: list;
      overflow: hidden; }
      .desktop-content .list a {
        text-decoration: none;
        font-family: 'Shrikhand', cursive;
        font-size: 50px;
        color: #000000; }
        .desktop-content .list a.active {
          color: #ff0097; }
    .desktop-content .project-name {
      position: relative;
      left: -400px;
      opacity: 0;
      margin-bottom: 4px; }
      .desktop-content .project-name:nth-child(1) {
        -webkit-animation: .2s ease .4s normal forwards 1 slidelefttoright;
                animation: .2s ease .4s normal forwards 1 slidelefttoright; }
      .desktop-content .project-name:nth-child(2) {
        -webkit-animation: .2s ease .5s normal forwards 1 slidelefttoright;
                animation: .2s ease .5s normal forwards 1 slidelefttoright; }
      .desktop-content .project-name:nth-child(3) {
        -webkit-animation: .2s ease .6s normal forwards 1 slidelefttoright;
                animation: .2s ease .6s normal forwards 1 slidelefttoright; }
      .desktop-content .project-name:nth-child(4) {
        -webkit-animation: .2s ease .7s normal forwards 1 slidelefttoright;
                animation: .2s ease .7s normal forwards 1 slidelefttoright; }
      .desktop-content .project-name:nth-child(5) {
        -webkit-animation: .2s ease .8s normal forwards 1 slidelefttoright;
                animation: .2s ease .8s normal forwards 1 slidelefttoright; }
      .desktop-content .project-name:nth-child(6) {
        -webkit-animation: .2s ease .9s normal forwards 1 slidelefttoright;
                animation: .2s ease .9s normal forwards 1 slidelefttoright; }
      .desktop-content .project-name:nth-child(7) {
        -webkit-animation: .2s ease 1s normal forwards 1 slidelefttoright;
                animation: .2s ease 1s normal forwards 1 slidelefttoright; }
      .desktop-content .project-name:nth-child(8) {
        -webkit-animation: .2s ease 1.1s normal forwards 1 slidelefttoright;
                animation: .2s ease 1.1s normal forwards 1 slidelefttoright; }
      .desktop-content .project-name:nth-child(9) {
        -webkit-animation: .2s ease 1.2s normal forwards 1 slidelefttoright;
                animation: .2s ease 1.2s normal forwards 1 slidelefttoright; }
  @-webkit-keyframes slidelefttoright {
    0% {
      left: -400px;
      opacity: 0; }
    60% {
      opacity: 0; }
    100% {
      left: 5px;
      opacity: 1; } }
  @keyframes slidelefttoright {
    0% {
      left: -400px;
      opacity: 0; }
    60% {
      opacity: 0; }
    100% {
      left: 5px;
      opacity: 1; } }
    .desktop-content .gif-wrapper {
      grid-area: gif;
      overflow: hidden;
      z-index: -1;
      -webkit-animation: .5s ease 1.5s normal forwards 1 sliderighttoleft;
              animation: .5s ease 1.5s normal forwards 1 sliderighttoleft;
      left: 800px;
      position: relative; }
      .desktop-content .gif-wrapper img {
        position: relative;
        width: 100%;
        display: block; }
      .desktop-content .gif-wrapper p {
        width: 100%;
        text-align: right;
        font-family: 'Chivo', sans-serif;
        font-weight: 300;
        font-style: italic;
        font-size: 18px;
        margin-top: 10px; }
  @-webkit-keyframes sliderighttoleft {
    0% {
      left: 800px; }
    100% {
      left: 0px; } }
  @keyframes sliderighttoleft {
    0% {
      left: 800px; }
    100% {
      left: 0px; } }
    .desktop-content .giffy {
      display: none; }
      .desktop-content .giffy.active-gif {
        display: block; } }

@media screen and (min-width: 1024px) {
  .desktop-content {
    grid-template-columns: 80px auto auto 80px;
    margin-top: 0; }
    .desktop-content .list {
      min-width: 430px; }
      .desktop-content .list a {
        font-size: 60px; } }

@media screen and (min-width: 1240px) {
  .desktop-content {
    grid-template-columns: auto auto;
    margin: 0 auto; }
    .desktop-content .list {
      min-width: 455px; }
      .desktop-content .list a {
        font-size: 64px; }
    .desktop-content .gif-wrapper {
      max-width: 600px;
      margin-left: -30px; } }

#root {
  position: relative; }

#loading-wrapper {
  background-color: #fff4e4;
  width: 100%;
  height: 100%;
  position: absolute;
  padding-top: 12px;
  z-index: 10;
  -webkit-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 1;
  text-align: center;
  top: 0; }

.name {
  font-family: 'Shrikhand', cursive;
  color: #ff0097;
  font-size: 91px;
  color: #ff0097;
  margin-left: 48px;
  text-align: left;
  margin-bottom: 10px;
  line-height: 1.1em; }

.animated-words span {
  position: absolute;
  opacity: 0;
  left: 48px;
  font-family: 'Chivo', sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 60px; }

.animated-words span:nth-of-type(1) {
  -webkit-animation: 1s ease 0s normal forwards 1 fadein, 1s ease 1s normal forwards 1 fadeout;
          animation: 1s ease 0s normal forwards 1 fadein, 1s ease 1s normal forwards 1 fadeout; }

.animated-words span:nth-of-type(2) {
  -webkit-animation: 1s ease 2s normal forwards 1 fadein, 1s ease 3s normal forwards 1 fadeout;
          animation: 1s ease 2s normal forwards 1 fadein, 1s ease 3s normal forwards 1 fadeout; }

.animated-words span:nth-of-type(3) {
  -webkit-animation: 1s ease 4s normal forwards 1 fadein, 1s ease 5s normal forwards 1 fadeout;
          animation: 1s ease 4s normal forwards 1 fadein, 1s ease 5s normal forwards 1 fadeout; }

.animated-words span:nth-of-type(4) {
  -webkit-animation: 1s ease 6s normal forwards 1 fadein, 1s ease 7s normal forwards 1 fadeout;
          animation: 1s ease 6s normal forwards 1 fadein, 1s ease 7s normal forwards 1 fadeout; }

.letters {
  -webkit-animation: 1s ease 7s normal forwards 1 fadeout;
          animation: 1s ease 7s normal forwards 1 fadeout; }

@-webkit-keyframes fadein {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadein {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeout {
  0% {
    opacity: 1; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeout {
  0% {
    opacity: 1; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@media screen and (min-width: 1024px) {
  #loading-wrapper {
    padding-top: 12px; }
    #loading-wrapper .name {
      margin-left: 78px; }
  .animated-words span {
    left: 80px; } }

.mainheader {
  display: grid;
  grid-template-columns: 25px 1fr 25px;
  grid-template-rows: 25px 1fr 25px; }
  .mainheader a {
    text-decoration: none;
    color: inherit; }
  .mainheader .logo {
    width: 43px;
    height: 34px;
    cursor: pointer; }
  .mainheader .desktop-nav {
    display: none; }
  .mainheader header {
    grid-column: 2 / 2;
    grid-row: 2 / 2;
    justify-content: space-between;
    align-items: center;
    display: flex;
    height: 50px;
    line-height: 49px; }
  .mainheader .hamburger, .mainheader .close {
    cursor: pointer;
    z-index: 2; }
  .mainheader .hamburger {
    height: 34px;
    width: 28px; }
  .mainheader .close {
    width: 34px;
    height: 34px; }
  .mainheader nav {
    display: flex;
    flex-direction: row-reverse;
    text-align: right;
    z-index: 1;
    position: absolute;
    background-color: rgba(246, 246, 238, 0.84);
    right: 0;
    top: 0;
    height: 180px;
    padding-top: 100px;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    width: 0;
    overflow: hidden; }
    .mainheader nav.active {
      width: 195px; }
    .mainheader nav ul {
      margin-right: 30px;
      font-family: 'Chivo', sans-serif;
      font-weight: 300;
      font-style: italic;
      font-size: 20px; }
      .mainheader nav ul li {
        cursor: pointer; }
        .mainheader nav ul li:not(:last-of-type) {
          margin-bottom: 20px; }
        .mainheader nav ul li:hover {
          color: #ff0097; }
      .mainheader nav ul .emoji {
        margin-left: 15px; }
  .mainheader .emoji {
    width: 40px;
    display: block;
    cursor: pointer; }

@media screen and (min-width: 640px) {
  .mainheader {
    grid-template-columns: 50px 1fr 50px; }
    .mainheader nav ul {
      margin-right: 50px; } }

@media screen and (min-width: 900px) {
  .mainheader {
    grid-template-rows: 40px 1fr 75px; }
    .mainheader .logo {
      width: 64px;
      height: 50px; }
  @-webkit-keyframes fadeHeaderIn {
    0% {
      opacity: 0; }
    50% {
      opacity: 0; }
    100% {
      opacity: 1; } }
  @keyframes fadeHeaderIn {
    0% {
      opacity: 0; }
    50% {
      opacity: 0; }
    100% {
      opacity: 1; } } }

@media screen and (min-width: 1024px) {
  .mainheader {
    display: grid;
    grid-template-columns: 80px 1fr 80px;
    grid-template-rows: 40px 1fr 75px; }
    .mainheader .hamburger, .mainheader nav {
      display: none; }
    .mainheader nav.active {
      display: none; }
    .mainheader .desktop-nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 250px; }
      .mainheader .desktop-nav li {
        display: inline;
        font-family: 'Chivo', sans-serif;
        font-weight: 300;
        font-style: italic;
        font-size: 24px; }
        .mainheader .desktop-nav li:hover {
          color: #ff0097; } }

/* Tooltip */
.tooltip-content {
  position: absolute;
  background: #ff0097;
  z-index: 500;
  min-width: 150px;
  margin-bottom: -1em;
  padding: 20px;
  border-radius: 20px;
  font-size: 20px;
  text-align: center;
  color: #fff;
  opacity: 0;
  cursor: default;
  pointer-events: none;
  font-family: 'Chivo', sans-serif;
  font-weight: 400;
  font-style: italic;
  -webkit-font-smoothing: antialiased;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.tooltip-east .tooltip-content {
  cursor: pointer;
  right: 150px;
  top: 0px;
  -webkit-transform-origin: calc(100% + 2em) 50%;
  transform-origin: calc(100% + 2em) 50%;
  -webkit-transform: translate3d(0, 50%, 0) rotate3d(1, 1, 1, -30deg);
  transform: translate3d(0, 50%, 0) rotate3d(1, 1, 1, -30deg); }

.tooltip:hover .tooltip-content {
  opacity: 1;
  -webkit-transform: translate3d(0, 50%, 0) rotate3d(0, 0, 0, 0);
  transform: translate3d(0, 50%, 0) rotate3d(0, 0, 0, 0);
  pointer-events: auto; }

/* Gap "bridge" and arrow */
.tooltip-content::before,
.tooltip-content::after {
  content: '';
  position: absolute; }

.tooltip-content::before {
  height: 100%;
  width: 3em; }

.tooltip-content::after {
  width: 2em;
  height: 2em;
  top: 50%;
  margin: -1em 0 0;
  background: url(/static/media/tooltip2.c635bc75.svg) no-repeat center center;
  background-size: 100%; }

.tooltip-east .tooltip-content::before,
.tooltip-east .tooltip-content::after {
  left: 99%;
  /* because of FF, otherwise we have a gap */ }

.tooltip-east .tooltip-content::after {
  -webkit-transform: scale3d(-1, 1, 1);
  transform: scale3d(-1, 1, 1); }

.about-wrapper {
  display: grid;
  grid-template-columns: 50px auto 50px;
  grid-template-rows: auto;
  margin-bottom: 80px; }
  .about-wrapper .intro {
    text-align: center;
    grid-column: 1 / 4; }
    .about-wrapper .intro h1 {
      margin: 28px 0; }
  .about-wrapper .text {
    margin: 0 50px; }
  .about-wrapper .section1 img,
  .about-wrapper .section2 img {
    width: 100%;
    display: block; }
  .about-wrapper .section1 {
    grid-column: 1 / 4; }
    .about-wrapper .section1 img {
      margin-top: 85px;
      margin-bottom: 35px; }
    .about-wrapper .section1 .text {
      text-align: left; }
  .about-wrapper .section2 {
    grid-column: 1 / 4; }
    .about-wrapper .section2 img {
      margin-top: 85px;
      margin-bottom: 35px; }
    .about-wrapper .section2 .text a {
      font-style: italic;
      text-decoration: none;
      color: #ff0097; }
      .about-wrapper .section2 .text a:hover {
        text-decoration: underline; }
  .about-wrapper .paws {
    width: 64px; }

@media screen and (min-width: 768px) {
  .about-wrapper {
    display: grid;
    justify-items: center;
    grid-template-columns: 12.5% 75% 12.5%;
    grid-template-rows: auto 75px auto 100px auto 200px;
    margin-bottom: 0; }
    .about-wrapper .intro {
      grid-column: 2 / 2;
      grid-row: 1 / 1; }
    .about-wrapper .section1 img,
    .about-wrapper .section2 img {
      width: 100%;
      max-width: 600px;
      margin: 0; }
    .about-wrapper .section1 {
      grid-column: 2 / 3;
      grid-row: 3 / 4;
      display: grid;
      grid-template-columns: 1fr 45px 1fr;
      grid-template-rows: auto; }
    .about-wrapper .section1 img {
      grid-column: 1 / 2;
      grid-row: 1 / 2; }
    .about-wrapper .text {
      margin: 0;
      font-size: 22px;
      max-width: 600px;
      align-self: center; }
    .about-wrapper .section1 .text {
      grid-column: 3 / 4; }
    .about-wrapper .section2 {
      grid-column: 2 / 3;
      grid-row: 5 / 6;
      display: grid;
      grid-template-columns: 1fr 45px 1fr;
      grid-template-rows: auto; }
    .about-wrapper .section2 img {
      grid-column: 3 / 4;
      grid-row: 1 / 2;
      place-self: center; }
    .about-wrapper .section2 .text {
      grid-column: 1 / 2;
      grid-row: 1 / 2; } }

@media screen and (min-width: 1024px) {
  .about-wrapper {
    grid-template-rows: auto 75px auto 100px auto 200px; }
    .about-wrapper .text {
      font-size: 26px; } }

.project-footer {
  margin-right: 25px;
  margin-top: 25px;
  margin-bottom: 25px; }

.project-footer a {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  text-decoration: none;
  color: inherit; }
  .project-footer a .eyes {
    align-self: center; }
  .project-footer a span {
    align-self: center;
    font-family: 'Chivo', sans-serif;
    font-weight: 300;
    font-style: italic;
    padding: 0 7px; }
  .project-footer a .arrow {
    align-self: center;
    padding-top: 15px; }

@media screen and (min-width: 640px) {
  .project-footer {
    margin-right: 50px; } }

@media screen and (min-width: 1024px) {
  .project-footer {
    margin-right: 80px;
    margin-top: 75px;
    margin-bottom: 75px; }
  .project-footer a {
    font-size: 25px; }
    .project-footer a span {
      padding: 0 10px; } }

.video-page-content {
  display: grid;
  grid-template-columns: 25px auto 25px;
  grid-template-rows: auto;
  grid-template-areas: ". copy ." "videos videos videos"; }

@media screen and (min-width: 640px) {
  .video-page-content {
    grid-template-columns: 50px auto 50px; } }

@media screen and (min-width: 1024px) {
  .video-page-content {
    grid-template-columns: 12.5%  75% 12.5%;
    grid-template-areas: ". copy ." ". videos ."; }
    .video-page-content .video-wrapper {
      margin-top: 0;
      margin-bottom: 90px; }
      .video-page-content .video-wrapper:first-of-type {
        margin-top: 65px; }
      .video-page-content .video-wrapper:last-of-type {
        margin-bottom: 0px; } }

.video-page-content .copy {
  grid-area: copy; }

.video-page-content .videos-wrapper {
  grid-area: videos; }

.video-wrapper {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  margin-top: 50px; }

.video {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow-y: scroll; }

.rebrand-page-content {
  display: grid;
  grid-template-columns: 25px auto 25px;
  grid-template-rows: auto;
  grid-template-areas: ". copy ." "videos videos videos" ". copy2 ." "section1 section1 section1" ". section2 ." ". section3 ." ". copy3 ." "video2 video2 video2"; }

.rebrand-page-content .copy {
  grid-area: copy; }

.rebrand-page-content .videos-wrapper {
  grid-area: videos;
  margin-top: 65px;
  margin-bottom: 40px; }
  .rebrand-page-content .videos-wrapper .video-wrapper {
    margin-top: 0; }

.rebrand-page-content .videos-wrapper-2 {
  grid-area: video2; }

.rebrand-page-content .copy2 {
  grid-area: copy2;
  margin-bottom: 30px; }

.rebrand-page-content .copy3 {
  grid-area: copy3;
  margin-top: 30px; }

.rebrand-page-content .section1 {
  grid-area: section1;
  text-align: center; }
  .rebrand-page-content .section1 p {
    margin-bottom: 40px; }
  .rebrand-page-content .section1 img {
    width: 100%;
    display: block;
    margin-bottom: 40px; }
    .rebrand-page-content .section1 img:first-of-type {
      margin-bottom: 20px; }
    .rebrand-page-content .section1 img:nth-of-type(2) {
      display: none; }

.rebrand-page-content .section2 {
  margin-bottom: 40px;
  grid-area: section2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 64px;
  grid-template-areas: "image1 image2"; }
  .rebrand-page-content .section2 img {
    width: 100%;
    display: block;
    max-width: 300px;
    place-self: center; }
    .rebrand-page-content .section2 img:first-of-type {
      grid-area: image1; }
    .rebrand-page-content .section2 img:last-of-type {
      grid-area: image2; }
    .rebrand-page-content .section2 img:nth-of-type(2) {
      display: none; }

.rebrand-page-content .section3 {
  grid-area: section3;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px; }
  .rebrand-page-content .section3 img {
    width: 100%; }
    .rebrand-page-content .section3 img:nth-of-type(2) {
      display: none; }
    .rebrand-page-content .section3 img:last-of-type {
      display: none; }

.rebrand-page-content .video-wrapper {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0; }

.rebrand-page-content .video {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute; }

.rebrand-page-content {
  grid-template-columns: 50px auto 50px; }

@media screen and (min-width: 768px) {
  .rebrand-page-content .section1 img {
    margin-bottom: 100px; }
    .rebrand-page-content .section1 img:first-of-type {
      margin-bottom: 70px; }
    .rebrand-page-content .section1 img:nth-of-type(2) {
      display: block; }
    .rebrand-page-content .section1 img:last-of-type {
      display: none; }
  .rebrand-page-content .section2 {
    margin-bottom: 100px; } }

@media screen and (min-width: 1024px) {
  .rebrand-page-content {
    grid-template-columns: 12.5%  75% 12.5%;
    grid-template-areas: ". copy ." ". videos ." ". copy2 ." ". section1 ." ". section2 ." ". section3 ." ". copy3 ." ". video2 ."; }
    .rebrand-page-content .videos-wrapper {
      margin-bottom: 70px; }
    .rebrand-page-content .copy3 {
      margin-top: 30px;
      margin-bottom: 30px; }
    .rebrand-page-content .videos-wrapper-2 {
      margin-top: 30px; }
      .rebrand-page-content .videos-wrapper-2 .video-wrapper {
        margin-top: 0; }
    .rebrand-page-content .copy2 {
      margin-bottom: 55px; }
    .rebrand-page-content .section1 img {
      margin-bottom: 100px; }
      .rebrand-page-content .section1 img:first-of-type {
        margin-bottom: 70px; }
    .rebrand-page-content .section2 {
      margin-bottom: 100px;
      grid-template-columns: 9fr 2fr 9fr 2fr 9fr;
      grid-column-gap: 0;
      grid-template-areas: "image1 . image3 . image2"; }
      .rebrand-page-content .section2 img:nth-of-type(1) {
        place-self: start; }
      .rebrand-page-content .section2 img:nth-of-type(2) {
        display: block;
        grid-area: image3; }
      .rebrand-page-content .section2 img:nth-of-type(3) {
        place-self: end; }
    .rebrand-page-content .section3 {
      display: grid;
      grid-template-columns: 58% 9.5% 32.5%;
      grid-template-rows: 1fr 0px 1fr;
      justify-content: initial;
      align-items: initial; }
      .rebrand-page-content .section3 img:first-of-type {
        max-width: 854px;
        grid-column: 1 / span 1;
        grid-row: 1 / span 3; }
      .rebrand-page-content .section3 img:nth-of-type(2) {
        align-self: start;
        grid-column: 3 / span 3;
        grid-row: 1 / span 1; }
      .rebrand-page-content .section3 img:last-of-type {
        align-self: end;
        grid-column: 3 / span 3;
        grid-row: 3 / span 3; }
      .rebrand-page-content .section3 img:not(:first-of-type) {
        display: block;
        max-width: 506px; } }

.grid-page-content {
  display: grid;
  grid-template-columns: 50px auto 50px;
  grid-template-rows: auto;
  grid-template-areas: ". copy ." "header-image header-image header-image" "image-grid image-grid image-grid"; }
  .grid-page-content .copy {
    grid-area: copy; }
  .grid-page-content .text a {
    color: #ff0097;
    text-decoration: none;
    font-style: italic; }
    .grid-page-content .text a:hover {
      text-decoration: underline; }
  .grid-page-content .header-image-wrapper {
    grid-area: header-image;
    margin-top: 50px;
    margin-bottom: 50px; }
    .grid-page-content .header-image-wrapper img {
      width: 100%;
      display: block;
      max-width: 1280px; }
  .grid-page-content .image-grid {
    grid-area: image-grid; }
    .grid-page-content .image-grid img {
      width: 100%;
      margin-bottom: 50px;
      display: block; }
      .grid-page-content .image-grid img:nth-of-type(5) {
        display: none; }
      .grid-page-content .image-grid img:last-of-type {
        margin-bottom: 0; }
    .grid-page-content .image-grid.cat-grid img:nth-of-type(5) {
      display: block; }

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .grid-page-content .image-grid {
    display: grid;
    grid-template-columns: 0px 1fr  1fr 0px;
    grid-template-rows: auto auto auto auto auto auto;
    grid-column-gap: 30px;
    grid-row-gap: 30px; }
    .grid-page-content .image-grid img {
      margin-bottom: 0; }
      .grid-page-content .image-grid img:nth-of-type(1) {
        grid-column: 2 / 3;
        grid-row: 1 / 2; }
      .grid-page-content .image-grid img:nth-of-type(2) {
        grid-column: 3 / 4;
        grid-row: 1 / 2; }
      .grid-page-content .image-grid img:nth-of-type(3) {
        grid-column: 2 / 3;
        grid-row: 2 / 3; }
      .grid-page-content .image-grid img:nth-of-type(4) {
        grid-column: 3 / 4;
        grid-row: 2 / 3; }
      .grid-page-content .image-grid img:nth-of-type(5) {
        grid-column: 2 / 3;
        grid-row: 3 / 5;
        display: block;
        place-self: center; }
      .grid-page-content .image-grid img:nth-of-type(6) {
        display: none; }
      .grid-page-content .image-grid img:nth-of-type(7) {
        grid-column: 3 / 4;
        grid-row: 3 / 4; }
      .grid-page-content .image-grid img:nth-of-type(8) {
        grid-column: 3 / 4;
        grid-row: 4/ 5; }
      .grid-page-content .image-grid img:nth-of-type(9) {
        grid-column: 2 / 3;
        grid-row: 5 / 6; }
      .grid-page-content .image-grid img:nth-of-type(10) {
        grid-column: 3 / 4;
        grid-row: 5 / 6; }
      .grid-page-content .image-grid img:nth-of-type(11) {
        grid-column: 2 / 3;
        grid-row: 6 / 7; }
      .grid-page-content .image-grid img:nth-of-type(12) {
        grid-column: 3 / 4;
        grid-row: 6 / 7; }
  .grid-page-content .image-grid.cat-grid {
    grid-template-rows: auto auto auto; }
    .grid-page-content .image-grid.cat-grid img:nth-of-type(1) {
      grid-column: 2 / 3;
      grid-row: 1 / 2; }
    .grid-page-content .image-grid.cat-grid img:nth-of-type(2) {
      grid-column: 3 / 4;
      grid-row: 1 / 2; }
    .grid-page-content .image-grid.cat-grid img:nth-of-type(3) {
      grid-column: 2 / 3;
      grid-row: 2 / 3; }
    .grid-page-content .image-grid.cat-grid img:nth-of-type(4) {
      grid-column: 3 / 4;
      grid-row: 2 / 3; }
    .grid-page-content .image-grid.cat-grid img:nth-of-type(5) {
      grid-column: 2 / 3;
      grid-row: 3 / 4; }
    .grid-page-content .image-grid.cat-grid img:nth-of-type(6) {
      grid-column: 3 / 4;
      grid-row: 3 / 4;
      display: block; } }

@media screen and (min-width: 1024px) {
  .grid-page-content {
    grid-template-columns: 12.5%  75% 12.5%;
    grid-template-areas: ". copy ." ". header-image ." ". image-grid ."; }
    .grid-page-content .title {
      margin-top: 20px;
      font-size: 60px; }
    .grid-page-content .role {
      font-size: 25px;
      margin-bottom: 15px; }
    .grid-page-content .description {
      max-width: 700px;
      margin: 0 auto;
      font-size: 23px;
      line-height: 1.4em; }
    .grid-page-content .header-image-wrapper {
      margin-top: 65px;
      margin-bottom: 50px; }
    .grid-page-content .image-grid {
      display: grid;
      grid-template-columns: 1fr  1fr 1fr;
      grid-template-rows: auto auto auto auto;
      grid-column-gap: 30px;
      grid-row-gap: 30px; } }
    @media screen and (min-width: 1024px) and (min-width: 1300px) {
      .grid-page-content .image-grid {
        grid-column-gap: 50px;
        grid-row-gap: 50px; } }

@media screen and (min-width: 1024px) {
      .grid-page-content .image-grid img {
        margin-bottom: 0; }
        .grid-page-content .image-grid img:nth-of-type(1) {
          grid-column: 1 / 2;
          grid-row: 1 / 2; }
        .grid-page-content .image-grid img:nth-of-type(2) {
          grid-column: 2 / 3;
          grid-row: 1 / 2; }
        .grid-page-content .image-grid img:nth-of-type(3) {
          grid-column: 3 / 4;
          grid-row: 1 / 2; }
        .grid-page-content .image-grid img:nth-of-type(4) {
          grid-column: 1 / 2;
          grid-row: 2 / 3; }
        .grid-page-content .image-grid img:nth-of-type(5) {
          grid-column: 2 / 3;
          grid-row: 2 / 4;
          place-self: center;
          display: block; }
        .grid-page-content .image-grid img:nth-of-type(6) {
          display: none; }
        .grid-page-content .image-grid img:nth-of-type(7) {
          grid-column: 3 / 4;
          grid-row: 2 / 3; }
        .grid-page-content .image-grid img:nth-of-type(8) {
          grid-column: 1 / 2;
          grid-row: 3 / 4; }
        .grid-page-content .image-grid img:nth-of-type(9) {
          grid-column: 3 / 4;
          grid-row: 3 / 4; }
        .grid-page-content .image-grid img:nth-of-type(10) {
          grid-column: 1 / 2;
          grid-row: 4 / 5; }
        .grid-page-content .image-grid img:nth-of-type(11) {
          grid-column: 2 / 3;
          grid-row: 4 / 5; }
        .grid-page-content .image-grid img:nth-of-type(12) {
          grid-column: 3 / 4;
          grid-row: 4 / 5; }
    .grid-page-content .image-grid.cat-grid {
      grid-template-rows: auto auto; }
      .grid-page-content .image-grid.cat-grid img:nth-of-type(1) {
        grid-column: 1 / 2;
        grid-row: 1 / 2; }
      .grid-page-content .image-grid.cat-grid img:nth-of-type(2) {
        grid-column: 2 / 3;
        grid-row: 1 / 2; }
      .grid-page-content .image-grid.cat-grid img:nth-of-type(3) {
        grid-column: 3 / 4;
        grid-row: 1 / 2; }
      .grid-page-content .image-grid.cat-grid img:nth-of-type(4) {
        grid-column: 1 / 2;
        grid-row: 2 / 3; }
      .grid-page-content .image-grid.cat-grid img:nth-of-type(5) {
        grid-column: 2 / 3;
        grid-row: 2 / 3; }
      .grid-page-content .image-grid.cat-grid img:nth-of-type(6) {
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        display: block; } }

.no-match-wrapper {
  display: grid;
  grid-template-columns: 50px auto 50px; }
  .no-match-wrapper .header {
    margin: 0; }
  .no-match-wrapper .img-wrapper {
    grid-column: 1 / 4; }
    .no-match-wrapper .img-wrapper img {
      width: 100%;
      display: block; }
  .no-match-wrapper .copy {
    grid-column: 2 / 3;
    text-align: center;
    margin-top: 45px;
    margin-bottom: 150px; }
    .no-match-wrapper .copy p {
      margin-top: 30px;
      margin-bottom: 30px; }
    .no-match-wrapper .copy a {
      font-family: 'Chivo', sans-serif;
      font-weight: 300;
      font-style: italic;
      text-decoration: none;
      color: #ff0097;
      font-size: 26px; }
      .no-match-wrapper .copy a:hover {
        text-decoration: underline; }

@media screen and (min-width: 768px) {
  .no-match-wrapper {
    grid-template-columns: 50px 1fr 45px 1fr 50px; }
    .no-match-wrapper .header {
      font-size: 40px; }
    .no-match-wrapper .img-wrapper {
      grid-column: 2 / 3; }
    .no-match-wrapper .copy {
      grid-column: 4 / 5;
      margin-top: 0;
      margin-bottom: 0;
      align-self: center; } }

@media screen and (min-width: 1300px) {
  .no-match-wrapper {
    grid-template-columns: 12.5% 1fr 45px 1fr 12.5%; } }

@media screen and (min-width: 1300px) {
  .no-match-wrapper .header {
    font-size: 50px; } }

.noScroll {
  overflow: hidden;
  height: 100%; }

.cat {
  position: relative;
  height: 170px;
  width: 192.1px; }

.ear {
  position: absolute;
  top: -30%;
  height: 60%;
  width: 25%;
  background: #ff0097; }
  .ear::before, .ear::after {
    content: '';
    position: absolute;
    bottom: 24%;
    height: 10%;
    width: 5%;
    border-radius: 50%;
    background: #161616; }
  .ear::after {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%; }

.ear--left {
  left: -7%;
  border-radius: 70% 30% 0% 0% / 100% 100% 0% 0%;
  -webkit-transform: rotate(-15deg);
          transform: rotate(-15deg); }
  .ear--left::before, .ear--left::after {
    right: 10%; }
  .ear--left::after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }

.ear--right {
  right: -7%;
  border-radius: 30% 70% 0% 0% / 100% 100% 0% 0%;
  -webkit-transform: rotate(15deg);
          transform: rotate(15deg); }
  .ear--right::before, .ear--right::after {
    left: 10%; }
  .ear--right::after {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }

.face {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #161616;
  border-radius: 50%; }

.eye {
  position: absolute;
  top: 35%;
  height: 30%;
  width: 31%;
  background: #fff;
  border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%; }
  .eye::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 100%;
    border-radius: 0 0 50% 50% / 0 0 40% 40%;
    background: #161616;
    -webkit-animation: blink 4s infinite ease-in;
            animation: blink 4s infinite ease-in; }

@-webkit-keyframes blink {
  0% {
    height: 0; }
  90% {
    height: 0; }
  92.5% {
    height: 100%; }
  95% {
    height: 0; }
  97.5% {
    height: 100%; }
  100% {
    height: 0; } }

@keyframes blink {
  0% {
    height: 0; }
  90% {
    height: 0; }
  92.5% {
    height: 100%; }
  95% {
    height: 0; }
  97.5% {
    height: 100%; }
  100% {
    height: 0; } }
  .eye::before {
    content: '';
    position: absolute;
    top: 60%;
    height: 10%;
    width: 15%;
    background: #fff;
    border-radius: 50%; }

.eye--left {
  left: 0; }
  .eye--left::before {
    right: -5%; }

.eye--right {
  right: 0; }
  .eye--right::before {
    left: -5%; }

.eye-pupil {
  position: absolute;
  top: 25%;
  height: 50%;
  width: 20%;
  background: #161616;
  border-radius: 50%;
  -webkit-animation: look-around 4s infinite;
          animation: look-around 4s infinite; }

@-webkit-keyframes look-around {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0); }
  5% {
    -webkit-transform: translate(50%, -25%);
            transform: translate(50%, -25%); }
  10% {
    -webkit-transform: translate(50%, -25%);
            transform: translate(50%, -25%); }
  15% {
    -webkit-transform: translate(-100%, -25%);
            transform: translate(-100%, -25%); }
  20% {
    -webkit-transform: translate(-100%, -25%);
            transform: translate(-100%, -25%); }
  25% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes look-around {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0); }
  5% {
    -webkit-transform: translate(50%, -25%);
            transform: translate(50%, -25%); }
  10% {
    -webkit-transform: translate(50%, -25%);
            transform: translate(50%, -25%); }
  15% {
    -webkit-transform: translate(-100%, -25%);
            transform: translate(-100%, -25%); }
  20% {
    -webkit-transform: translate(-100%, -25%);
            transform: translate(-100%, -25%); }
  25% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }
  .eye--left .eye-pupil {
    right: 30%; }
  .eye--right .eye-pupil {
    left: 30%; }
  .eye-pupil::after {
    content: '';
    position: absolute;
    top: 30%;
    right: -5%;
    height: 20%;
    width: 35%;
    border-radius: 50%;
    background: #fff; }

.muzzle {
  position: absolute;
  top: 60%;
  left: 50%;
  height: 6%;
  width: 10%;
  background: #ff0097;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-radius: 50% 50% 50% 50% / 30% 30% 70% 70%; }

/* General page styling */
#cat-wrapper {
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: #161616;
  position: absolute;
  width: 100%;
  z-index: 1000;
  display: none; }
  #cat-wrapper.active {
    display: flex; }

@-webkit-keyframes fadein {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadein {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
body {
  background-color: #fff4e4; }

.header {
  font-family: 'Shrikhand', cursive;
  color: #ff0097;
  text-align: center;
  font-size: 36px;
  margin-bottom: 30px;
  margin-top: 50px; }

.role {
  text-align: center;
  font-family: 'Chivo', sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 22px;
  margin-bottom: 10px; }

.text {
  text-align: center;
  font-family: 'Chivo', sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.4em; }

@media screen and (min-width: 1024px) {
  .header {
    font-size: 65px;
    margin-bottom: 40px;
    margin-top: 0px; }
  .role {
    font-size: 25px;
    margin-bottom: 15px; }
  .text {
    max-width: 700px;
    margin: 0 auto;
    font-size: 23px;
    line-height: 1.4em; } }

