$magenta: #ff0097;

.mainheader {
  display: grid;
  grid-template-columns: 25px 1fr 25px;
  grid-template-rows: 25px 1fr 25px;

  a {
    text-decoration: none;
    color: inherit;
  }

  .logo {
    width: 43px;
    height: 34px;
    cursor: pointer;
  }

  .desktop-nav {
    display: none;
  }

  header {
    grid-column: 2 / 2;
    grid-row: 2 / 2;
    justify-content: space-between;
    align-items: center;
    display: flex;
    height: 50px;
    line-height: 49px;
  }

  .hamburger, .close {
    cursor: pointer;
    z-index: 2;
  }

  .hamburger {
    height: 34px;
    width: 28px;
  }

  .close {
    width: 34px;
    height: 34px;
  }

  nav {
    display: flex;
    flex-direction: row-reverse;
    text-align: right;
    z-index: 1;
    position: absolute;
    background-color: rgba(246, 246, 238, 0.84);
    right: 0;
    top: 0;
    height: 180px;
    padding-top: 100px;
    transition: all .5s ease;
    width: 0;
    overflow: hidden;

    &.active {
      width: 195px;
    }

    ul {
      margin-right: 30px;
      font-family: 'Chivo', sans-serif;
      font-weight: 300;
      font-style: italic;
      font-size: 20px;

      li {
        cursor: pointer;

        &:not(:last-of-type) {
          margin-bottom: 20px;
        }

        &:hover {
          color: $magenta;
        }
      }

      .emoji {
        margin-left: 15px;
      }
    }
  }

  .emoji {
    width: 40px;
    display: block;
    cursor: pointer;
  }
}



@media screen and (min-width: 640px) {
  .mainheader {
    grid-template-columns: 50px 1fr 50px;

    nav ul {
      margin-right: 50px;
    }
  }
}

@media screen and (min-width: 900px) {
  .mainheader {
    // animation: .5s ease 0s normal forwards 1 fadeHeaderIn;
    grid-template-rows: 40px 1fr 75px;

    .logo {
      width: 64px;
      height: 50px;
    }

    @keyframes fadeHeaderIn {
      0% { opacity: 0; }
      50% { opacity: 0; }
      100% { opacity: 1; }
    }
  }
}

@media screen and (min-width: 1024px) {
  .mainheader {
    display: grid;
    grid-template-columns: 80px 1fr 80px;
    grid-template-rows: 40px 1fr 75px;

    .hamburger, nav {
      display: none;
    }

    nav.active {
      display: none;
    }

    .desktop-nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 250px;

      li {
        display: inline;
        font-family: 'Chivo', sans-serif;
        font-weight: 300;
        font-style: italic;
        font-size: 24px;

        &:hover {
          color: #ff0097;
        }
      }
    }
  }
}