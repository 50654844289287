$magenta: #ff0097;

/* Tooltip */

.tooltip-content {
	position: absolute;
  background: #ff0097;
	z-index: 500;
	min-width: 150px;
	margin-bottom: -1em;
	padding: 20px;
	border-radius: 20px;
	font-size: 20px;
	text-align: center;
	color: #fff;
	opacity: 0;
	cursor: default;
	pointer-events: none;
  font-family: 'Chivo', sans-serif;
  font-weight: 400;
  font-style: italic;
	-webkit-font-smoothing: antialiased;
	-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
	transition: opacity 0.3s, transform 0.3s;
}

.tooltip-east .tooltip-content {
  cursor: pointer;
  right: 150px;
  top: 0px;
	-webkit-transform-origin: calc(100% + 2em) 50%;
	transform-origin: calc(100% + 2em) 50%;
	-webkit-transform: translate3d(0,50%,0) rotate3d(1,1,1,-30deg);
	transform: translate3d(0,50%,0) rotate3d(1,1,1,-30deg);
}

.tooltip:hover .tooltip-content {
	opacity: 1;
	-webkit-transform: translate3d(0,50%,0) rotate3d(0,0,0,0);
	transform: translate3d(0,50%,0) rotate3d(0,0,0,0);
	pointer-events: auto;
}

/* Gap "bridge" and arrow */

.tooltip-content::before,
.tooltip-content::after {
	content: '';
	position: absolute;
}

.tooltip-content::before {
	height: 100%;
	width: 3em;
}

.tooltip-content::after {
	width: 2em;
	height: 2em;
	top: 50%;
	margin: -1em 0 0;
	background: url(./tooltip2.svg) no-repeat center center;
	background-size: 100%;
}

.tooltip-east .tooltip-content::before,
.tooltip-east .tooltip-content::after {
	left: 99%; /* because of FF, otherwise we have a gap */
}

.tooltip-east .tooltip-content::after {
	-webkit-transform: scale3d(-1,1,1);
	transform: scale3d(-1,1,1);
}