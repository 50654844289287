.video-page-content {
  display: grid;
  grid-template-columns: 25px auto 25px;
  grid-template-rows: auto;
  grid-template-areas:
    ". copy ."
    "videos videos videos"
}

@media screen and (min-width: 640px) {
  .video-page-content {
    grid-template-columns: 50px auto 50px;
  }
}

@media screen and (min-width: 1024px) {
  .video-page-content {
    grid-template-columns: 12.5%  75% 12.5%;
    grid-template-areas:
    ". copy ."
    ". videos .";

    .video-wrapper {
      margin-top: 0;
      margin-bottom: 90px;

      &:first-of-type {
        margin-top: 65px;
      }

      &:last-of-type {
        margin-bottom: 0px;
      }
    }
  }
}

.video-page-content {
  .copy {
    grid-area: copy;
  }

  .videos-wrapper {
    grid-area: videos;
  }
}

.video-wrapper {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  margin-top: 50px;
}

.video {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow-y: scroll;
}