$magenta: #ff0097;

body {
  background-color: #fff4e4;
}

.header {
  font-family: 'Shrikhand', cursive;
  color: $magenta;
  text-align: center;
  font-size: 36px;
  margin-bottom: 30px;
  margin-top: 50px;
}

.role {
  text-align: center;
  font-family: 'Chivo', sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 22px;
  margin-bottom: 10px;
}

.text {
  text-align: center;
  font-family: 'Chivo', sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.4em;
}

@media screen and (min-width: 1024px) {
  .header {
    font-size: 65px;
    margin-bottom: 40px;
    margin-top: 0px;
  }

  .role {
    font-size: 25px;
    margin-bottom: 15px;
  }

  .text {
    max-width: 700px;
    margin: 0 auto;
    font-size: 23px;
    line-height: 1.4em;
  }
}