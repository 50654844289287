$magenta: #ff0097;

.wrapper {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-template-areas:
    "content";
  z-index: 1;
}

.mobile-content {
  grid-area: content;
  grid-row: 1/1;
  width: 100%;

  .project {
    position: relative;
    cursor: pointer;
    width: 100%;
    min-height: 175px;

    img {
      display: block;
      width: 100%;
    }

    h2 {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-family: 'Shrikhand', cursive;
      font-size: 50px;
      color: #ff0097;
      text-align: center;
    }
  }
}

.desktop-content {
  display: none;
}

// Desktop
@media screen and (min-width: 900px) {
  .mobile-content {
    display: none;
  }

  .desktop-content {
    overflow: hidden;
    display: grid;
    grid-template-columns: 50px auto auto 50px;
    grid-template-rows: auto;
    grid-template-areas:
      ". list gif .";
    margin-top: 50px;

    .list {
      min-width: 360px;

      grid-area: list;
      overflow: hidden;

      a {
        text-decoration: none;
        font-family: 'Shrikhand', cursive;
        font-size: 50px;
        color: #000000;

        &.active {
          color: $magenta;
        }
      }
    }

    .project-name {
      position: relative;
      left: -400px;
      opacity: 0;
      margin-bottom: 4px;

      &:nth-child(1) {
        animation: .2s ease .4s normal forwards 1 slidelefttoright;
      }

      &:nth-child(2) {
        animation: .2s ease .5s normal forwards 1 slidelefttoright;
      }

      &:nth-child(3) {
        animation: .2s ease .6s normal forwards 1 slidelefttoright;
      }

      &:nth-child(4) {
        animation: .2s ease .7s normal forwards 1 slidelefttoright;
      }

      &:nth-child(5) {
        animation: .2s ease .8s normal forwards 1 slidelefttoright;
      }

      &:nth-child(6) {
        animation: .2s ease .9s normal forwards 1 slidelefttoright;
      }

      &:nth-child(7) {
        animation: .2s ease 1s normal forwards 1 slidelefttoright;
      }

      &:nth-child(8) {
        animation: .2s ease 1.1s normal forwards 1 slidelefttoright;
      }

      &:nth-child(9) {
        animation: .2s ease 1.2s normal forwards 1 slidelefttoright;
      }

      @keyframes slidelefttoright{
        0% {
          left: -400px;
          opacity: 0;
        }

        60% {
          opacity: 0;
        }

        100% {
          left: 5px;
          opacity: 1;
        }
      }
    }

    .gif-wrapper {
      grid-area: gif;
      overflow: hidden;
      z-index: -1;
      animation: .5s ease 1.5s normal forwards 1 sliderighttoleft;
      left: 800px;
      position: relative;

      img {
        position: relative;
        width: 100%;
        display: block;
      }

      p {
        width: 100%;
        text-align: right;
        font-family: 'Chivo', sans-serif;
        font-weight: 300;
        font-style: italic;
        font-size: 18px;
        margin-top: 10px;
      }

      @keyframes sliderighttoleft {
        0% { left: 800px; }
        100% { left: 0px; }
      }
    }

    .giffy {
      display: none;

      &.active-gif {
        display: block;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .desktop-content {
    grid-template-columns: 80px auto auto 80px;
    margin-top: 0;

    .list {
      min-width: 430px;

      a {
        font-size: 60px;
      }
    }
  }
}

@media screen and (min-width: 1240px) {
  .desktop-content {
    grid-template-columns: auto auto;
    margin: 0 auto;

    .list {
      min-width: 455px;
      a {
        font-size: 64px;
      }
    }

    .gif-wrapper {
      max-width: 600px;
      margin-left: -30px;
    }
  }
}